<template>
  <main id="reporting-view">
    <div class="card" id="main-card">
      <form action="#create" class="wizard-form" @submit="onSubmit" ref="create-form">
        <div class="card__content">
          <div class="card__header">
            <h4 class="card__title">{{ $t('new-template') }}</h4>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w75">
              <input type="text" class="input input__text" required name="name" id="name" v-model="name" autocomplete="new-password">
              <label for="name" class="input__label">{{ $t('name') }} *</label>
            </fieldset>
            <fieldset class="form__field --w25">
              <label for="category" class="input__label input__label__multivalue">{{ $t('category') }}</label>
              <select class="input input__select" name="category" id="category" v-model="category">
                <option value=""></option>
                <option
                  v-for="(dataItem, index) in templateCategories"
                  :value="dataItem.value"
                  :key="index">{{ dataItem.label }}</option>
              </select>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w100">
              <input type="text" class="input input__text" name="description" id="description" v-model="description" autocomplete="new-password">
              <label for="description" class="input__label">{{ $t('description') }} </label>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w25">
              <input type="date" class="input input__date input__date--from" name="startDate" id="startDate" v-model="startDate" autocomplete="new-password">
              <label for="startDate" class="input__label">{{ $t('start-date') }}</label>
            </fieldset>
            <fieldset class="form__field --w25">
              <input type="date" class="input input__date input__date--to" name="endDate" id="endDate" v-model="endDate" autocomplete="new-password">
              <label for="endDate" class="input__label">{{ $t('end-date') }}</label>
            </fieldset>
            <fieldset class="form__field --w50">
              <label for="segmentation" class="input__label input__label__multivalue">{{ $t('segmentation') }}</label>
              <select class="input input__select" name="segmentation" id="segmentation" v-model="segmentation">
                <option value=""></option>
                <option
                  v-for="(dataItem, index) in segmentationValues"
                  :value="dataItem.value"
                  :disabled="dataItem.disabled"
                  :key="index">{{ dataItem.label }}</option>
              </select>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w100">
              <label class="input__label input__label__multivalue">{{ $t('metrics') }} *</label>
              <template v-for="(metric, index) in metricsValues">
                <input class="input__checkbox"
                  :key="`input-${index}`"
                  :class="[ metrics.includes(metric.value) ? 'input__checkbox--checked' : '' ]"
                  type="checkbox"
                  v-model="metrics"
                  :checked="metrics.includes(metric.value)"
                  :value="metric.value"
                  :id="metric.value"
                  name="metrics" />
                <label :key="`label-${index}`" class="input__label input__label--checkbox input__label__singlevalue" :for="metric.value">{{ metric.label }}</label>
              </template>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w50">
              <label class="input__label input__label__multivalue">{{ $t('options') }}</label>
              <template v-for="(option, index) in optionValues">
                <input class="input__checkbox"
                  :key="`input-${index}`"
                  :class="[ options.includes(option.value) ? 'input__checkbox--checked' : '' ]"
                  type="checkbox"
                  v-model="options"
                  :checked="options.includes(option.value)"
                  :value="option.value"
                  :id="option.value"
                  name="options" />
                <label :key="`label-${index}`" class="input__label input__label--checkbox input__label__singlevalue" :for="option.value">{{ option.label }}</label>
              </template>
            </fieldset>
            <fieldset class="form__field --w50">
              <label for="format" class="input__label input__label__multivalue">{{ $t('format') }}</label>
              <select class="input input__select" name="format" id="format" v-model="format">
                <option
                  v-for="(dataItem, index) in formatValues"
                  :value="dataItem.value"
                  :disabled="dataItem.disabled"
                  :key="index">{{ dataItem.label }}</option>
              </select>
            </fieldset>
          </div>
        </div>
        <div class="modal__actions">
          <div class="modal__actions--left">
            <button type="button" class="btn btn--text" @click="onCancel">{{ $t('cancel') }}</button>
          </div>
          <div class="modal__actions--right">
            <button type="button" class="btn btn--new" @click="onSubmit">{{ $t('save') }}</button>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>
<script>
import {
  mapGetters,
  mapActions
} from 'vuex'
import {
  SHOW_MODAL,
  HIDE_MODAL,
  GET_TEMPLATES,
  SAVE_NEW_TEMPLATE,
  SHOW_NOTIFICATION,
  UPDATE_TEMPLATE,
  GET_REPORT,
  HIDE_REPORT,
  SEARCH_ORDERS,
  SEARCH_CAMPAIGNS
} from '@/core/action-types'
import i18n from '@/plugins/i18n'
export default {
  name: 'new-template',
  components: {},
  data: function () {
    return {
      pagination: {
        currentPage: 1,
        perPage: 40,
        totalPages: 1,
        total: 0
      },
      orderField: 'none',
      orderedTableItems: [],
      searchText: '',
      fieldsFilter: [],
      isFieldsSelectorVisible: false,
      isFieldsFilterActive: false,
      fieldsMeta: [
        {
          id: 'category',
          label: this.$t('category'),
          values: [
            { id: 'category-performance', label: this.$t('performance') },
            { id: 'category-billclosure', label: this.$t('bill-closure') }
          ]
        },
        {
          id: 'user',
          label: this.$t('user'),
          values: []
        }
      ],
      showFullWidthName: false,
      locale: i18n.locale,
      filteredTemplates: [],
      templateCategories: [
        { value: 'performance', label: this.$t('performance'), icon: '/img/baseline_speed_white_48dp.png' },
        { value: 'billclosure', label: this.$t('bill-closure'), icon: '/img/baseline_payments_white_48dp.png' }
      ],
      segmentationValues: [
        { label: this.$t('objective'), value: 'objective' },
        { label: this.$t('day'), value: 'day', disabled: true },
        { label: this.$t('gender'), value: 'gender', disabled: true },
        { label: this.$t('age'), value: 'age', disabled: true }
      ],
      metricsValues: [
        { label: this.$t('budget'), value: 'budget' },
        { label: this.$t('cpr-cpm'), value: 'cpr' },
        { label: this.$t('results'), value: 'results' },
        { label: this.$t('progress'), value: 'progress' },
        { label: this.$t('spend'), value: 'spend' },
        { label: this.$t('ecpr'), value: 'ecpr' },
        { label: this.$t('impressions'), value: 'impressions' },
        { label: this.$t('ctr'), value: 'ctr' },
        { label: this.$t('results_rate'), value: 'results_rate' },
        { label: this.$t('reach'), value: 'reach' },
        { label: this.$t('frequency'), value: 'frequency' }
      ],
      optionValues: [
        { label: this.$t('summary_fields'), value: 'summary_fields' }
      ],
      formatValues: [
        { label: 'csv', value: 'csv' },
        { label: 'pdf', value: 'pdf' },
        { label: 'xls', value: 'xls' }
      ],
      name: '',
      description: '',
      category: '',
      segmentation: 'objective',
      metrics: [],
      options: [],
      startDate: undefined,
      endDate: undefined,
      format: ''
    }
  },
  computed: {
    ...mapGetters([
      'authData',
      'templates',
      'reportData',
      'foundEntities'
    ]),
    filteredTemplatesTags () {
      return this.templates && this.templates.length > 0 && this.filteredTemplates && this.filteredTemplates.length > 0 ? this.filteredTemplates.map(templateId => {
        return {
          value: templateId,
          label: this.templates.find(template => template._id === templateId).name
        }
      }) : []
    },
    tabSavedReportsChecked () {
      return this.$route.params.tab === 'saved-reports'
    },
    tabCreateReportChecked () {
      return this.$route.params.tab === 'create-report'
    }
  },
  mounted () {
    this.fetchData()
    this.orderField = window.localStorage.getItem('orderField_templates') || 'none'
    this.fieldsFilter = window.localStorage.getItem('fieldsFilter_templates') ? window.localStorage.getItem('fieldsFilter_templates').split(',') : []
  },
  watch: {
    $route (to, from) {
      this.fetchData()
    },
    orderField (newValue, oldValue) {
      window.localStorage.setItem('orderField_templates', newValue)
    },
    fieldsFilter (newValue, oldValue) {
      window.localStorage.setItem('fieldsFilter_templates', newValue.join(','))
      this.isFieldsFilterActive = true
    },
    templates (newValue, oldValue) {
      this.pagination.total = newValue.length
      this.pagination.totalPages = Math.ceil(newValue.length / this.pagination.perPage)
      this.pagination.currentPage = 1
      this.orderedTableItems = this.orderTableByField(newValue)
    }
  },
  methods: {
    ...mapActions({
      showModal: SHOW_MODAL,
      hideModal: HIDE_MODAL,
      getTemplates: GET_TEMPLATES,
      saveNewTemplate: SAVE_NEW_TEMPLATE,
      showNotification: SHOW_NOTIFICATION,
      updateTemplate: UPDATE_TEMPLATE,
      getReport: GET_REPORT,
      hideReport: HIDE_REPORT,
      searchOrders: SEARCH_ORDERS,
      searchCampaigns: SEARCH_CAMPAIGNS
    }),
    setCurrentPage (page) {
      this.pagination.currentPage = page
    },
    isRowVisible (index) {
      return index >= (this.pagination.currentPage - 1) * this.pagination.perPage && index < this.pagination.currentPage * this.pagination.perPage
    },
    orderTableByField (table) {
      const sortArray = function (a, b) {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        return 0
      }
      const sortTextArray = function (a, b) {
        return a.localeCompare(b)
      }
      let sortedTable = (table) ? [...table] : []
      switch (this.orderField) {
        case 'name':
          sortedTable = sortedTable.sort((a, b) => sortTextArray(a.name.toUpperCase(), b.name.toUpperCase()))
          break
        case 'user':
          sortedTable = sortedTable.sort((a, b) => sortTextArray(a.role.toUpperCase(), b.role.toUpperCase()))
          break
        case 'created_at':
          sortedTable = sortedTable.sort((a, b) => sortArray(new Date(a.start_date), new Date(b.start_date)))
          break
        case 'none':
          break
        default:
          sortedTable = sortedTable.sort((a, b) => sortArray(Number(a[this.orderField]), Number(b[this.orderField])))
      }
      return sortedTable
    },
    changeTableOrder (field) {
      this.orderField = field
      this.orderedTableItems = this.orderTableByField(this.templates)
    },
    isRowFiltered (row) {
      const filters = this.fieldsFilter.map(item => item.split('-'))
      let allFiltersPassed = {}
      filters.forEach(filter => { allFiltersPassed[filter[0]] = false })
      filters.forEach(filter => {
        if (row[filter[0]] === filter[1] || row[filter[0]] === Number(filter[1])) {
          allFiltersPassed[filter[0]] = true
        }
      })
      return Object.keys(allFiltersPassed).map(key => allFiltersPassed[key]).every(value => value === true)
    },
    fetchData () {
      this.getTemplates()
    },
    hideReportData () {
      this.hideReport()
    },
    translateOptionsString (optionsObject) {
      return optionsObject ? Object.keys(optionsObject).map(item => `${this.$t(item)}${typeof optionsObject[item] === 'string' ? ` (${optionsObject[item]})` : ''}`).join(', ') : '-'
    },
    translateFilenameString (url, name) {
      let urlFragments = url.split('.')
      return `${name.toLowerCase()}.${urlFragments[urlFragments.length - 1]}`
    },
    translateColClass (type) {
      let colClass = ''
      switch (type) {
        case 'string':
          colClass = '--w300px'
          break
        case 'date':
          colClass = '--ta-c --w150px'
          break
        case 'float':
          colClass = '--ta-r --w100px'
          break
        case 'int':
          colClass = '--ta-r --w100px'
          break
      }
      return colClass
    },
    translateColValue (value, type) {
      let colValue = ''
      switch (type) {
        case 'string':
          colValue = value
          break
        case 'date':
          colValue = this.formatDate(value)
          break
        case 'float':
          colValue = Number(value).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })
          break
        case 'int':
          colValue = Number(value).toLocaleString()
          break
      }
      return colValue
    },
    translateSummaryColValue (value) {
      let colValue = ''
      switch (typeof value) {
        case 'number':
          colValue = Number(value).toLocaleString()
          break
        default:
          colValue = value
      }
      return colValue
    },
    addParamsToLocation () {
      const params = this.$route.query
      const queryString = Object.keys(params).map(key => {
        return (typeof params[key] === 'string') ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}` : params[key].map(keyValue => `${key}=${keyValue}`).join('&')
      }).join('&')
      window.history.pushState({}, null, `${this.$route.path}?${queryString}`)
    },
    formatDate (date) {
      return date && date !== '' ? new Date(date).toLocaleString(this.locale, { year: 'numeric', month: 'numeric', day: 'numeric' }) : ''
    },
    createTemplate () {
      this.$router.push({ name: 'reporting-new', query: this.$route.query })
    },
    clearSearchText () {
      this.searchText = ''
    },
    checkMetricsValidity () {
      if (this.metrics.length === 0) {
        this.showNotification({ text: this.$t('metrics-needed'), type: 'error', icon: 'error' })
        return false
      } else {
        return true
      }
    },
    // EVENTS:
    onCancel () {
      this.$router.push({ name: 'templates', query: this.$route.query })
    },
    onSubmit () {
      if (this.$refs['create-form'].reportValidity() && this.checkMetricsValidity()) {
        let query = {
          name: this.name,
          description: this.description !== '' ? this.description : null,
          category: this.category !== '' ? this.category : null,
          segmentation: this.segmentation !== '' ? this.segmentation : null,
          metrics: this.metrics,
          start_date: this.startDate !== undefined ? this.startDate : null,
          end_date: this.endDate && this.endDate !== undefined ? this.endDate : null,
          public: false
        }
        query.options = {}
        if (this.options.includes('summary_fields')) {
          query.options.summary_fields = 'true'
        } else {
          query.options.summary_fields = null
        }
        if (this.format !== '') {
          query.options.format = this.format
        }
        if (Object.keys(query.options).length === 0) {
          delete query.options
        }
        if (this.authData?.role === 'admin') {
          query.public = true
        }
        const onComplete = function (responseData) {
          this.showNotification({ text: this.$t('template-created'), type: 'success', icon: 'success' })
          this.$router.push({ name: 'templates', query: this.$route.query })
        }
        query.onComplete = onComplete.bind(this)
        this.saveNewTemplate(query)
      }
    },
    fieldsFilterShowModal () {
      this.isFieldsSelectorVisible = true
    },
    onFieldsFilterHideModal () {
      this.isFieldsSelectorVisible = false
    },
    onFieldsFilterChange ({ fieldsFilter }) {
      this.fieldsFilter = [...fieldsFilter]
    },
    onFilteredTemplatesChange (e) {
      let filteredTemplates
      if (e.target.checked) {
        filteredTemplates = [...this.filteredTemplates, e.target.value]
      } else {
        filteredTemplates = [...this.filteredTemplates.filter(item => item !== e.target.value)]
      }
      this.$emit('on-filtered-templates-change', { filteredTemplates })
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
#reporting-view {
  padding: 2rem 2rem 100px;
  display: flex;
  flex-direction: column;
}
.card__header + .card--with-header {
  margin-bottom: 20px;
}
.dashboard-header {
  display: flex;
  line-height: 36px;
  justify-content: space-between;
  padding: 10px 20px;
}
.dashboard-header__breadcrumb {
  font-size: 24px;
  flex-basis: calc(100% - 650px);
  max-width: calc(100% - 660px);
  display: flex;
}
.dashboard-header__form {
  display: flex;
  position: relative;
}
#btn-clear-text {
  position: absolute;
  left: 240px;
}
/* INTEGRAR CON ESITLOS GLOBALES */
.form__field {
  position: relative;
  padding: 0 0 7px;
  margin: 20px 0 0 0;
  border: none;
}
.form__field--only-text {
  padding: 0 20px 7px;
}
.form__field--no-label {
  margin-top: 0;
}
.input {
  border-width: 0;
  /* background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2); */
  /* background-size: 0 2px, 100% 1px; */
  /* background-repeat: no-repeat; */
  /* background-position: center bottom, center calc(100% - 1px); */
  /* background-color: transparent; */
  box-shadow: none;
  /* border-radius: 0; */
  color: var(--app-font-color);
  float: none;
  font-size: var(--app-base-fontsize);
  font-weight: var(--app-font-weight);
  height: 36px;
  line-height: var(--app-line-height);
  margin: 0 0 7px;
  padding: 7px 0;
  /* transition: background 0s ease-out; */
  width: 100%;
  outline: none;
}
.input:disabled {
  color: var(--app-disabled-color);
  cursor: default;
  /* background-color: rgba(235, 235, 228, 0.3); */
}
.input:-internal-autofill-selected {
  /* appearance: menulist-button; */
  /* background-color: #e8f0fe !important; */
  background-color: var(--app-light-blue) !important;
  /* background-image: none !important; */
  /* color: -internal-light-dark(black, white) !important; */
}
.input__text {
  padding: 7px 20px;
}
.input__date {
  padding: 7px 0px 7px 20px;
}
/* .input__date {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_today_black_48dp.png');
  background-size: 0 2px, 100% 1px, 24px 24px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px), calc(100% - 3px) 3px;
  background-color: transparent;
}
.input__date--from {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_today_black_48dp.png');
}
.input__date--to {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_event_black_48dp.png');
}
.input__text:focus {
  outline: none;
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
} */
.input__label {
  color: var(--app-disabled-color);
  cursor: text;
  font-size: var(--app-base-fontsize);
  font-weight: var(--app-font-weight);
  line-height: var(--input-line-height);
  margin: 10px 0 0;
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
  top: -7px;
  left: 20px;
  will-change: left, top, contents;
}
.input__date ~ .input__label,
.input__text:focus ~ .input__label,
.input__text:valid:not(.input--empty) ~ .input__label,
.input__text:-webkit-autofill ~ .input__label,
.input__text:read-only ~ .input__label,
.input__text:disabled ~ .input__label,
.input__label--focus,
.input__label__multivalue,
.input__label--invalid,
.input__text--invalid,
.input__text:invalid,
.input__text:invalid:not(:-webkit-autofill) ~ .input__label {
  top: -30px;
  left: 20px;
  font-size: 11px;
  line-height: 1;
}
.input__label--invalid,
.input__text--invalid,
.input__date__label--invalid,
.input__text:invalid,
.input__text:invalid:not(:-webkit-autofill) ~ .input__label {
  color: var(--app-dark-red);
}
/* .input__label__multivalue {
  margin: 10px 0 0;
  position: relative;
  display: block;
  top: unset;
  font-size: 11px;
  line-height: 1;
} */
.input__label__singlevalue {
  /* margin: 0 10px 10px 0; */
  margin: 5px 10px 10px 2px;
  border: none;
  border-radius: 4px;
  /* background-color: var(--card-background-color);
  background-image: linear-gradient(black, black), url('/img/baseline_radio_button_unchecked_white_48dp.png');
  background-repeat: no-repeat;
  background-position-x: 3px;
  background-position-y: 3px;
  background-size: 25px;
  background-blend-mode: difference; */
  line-height: var(--input-line-height);
  padding: 0px 10px 0px 25px;
  position: relative;
  display: inline-block;
  top: unset;
  pointer-events: unset;
  cursor: pointer;
  color: black;
}
/* .input__label__singlevalue.input__label--checkbox {
  background-image: linear-gradient(black, black), url('/img/baseline_check_box_outline_blank_white_48dp.png');
} */
.input__radio--checked + .input__label__singlevalue.input__label--radio,
.input__checkbox--checked + .input__label__singlevalue.input__label--checkbox {
  /* background-image: linear-gradient(black, black), url('/img/baseline_check_box_white_48dp.png'); */
  color: var(--app-dark-blue);
}
.input__label__singlevalue:hover {
    outline: none;
    box-shadow: var(--app-shadow-color);
}
.input__radio {
  display: none;
}
/* .input__radio--checked + .input__label__singlevalue,
.input__radio[checked] + .input__label__singlevalue {
    background-image: linear-gradient(black, black), url('/img/baseline_radio_button_checked_white_48dp.png');
} */
.input__radio--disabled + .input__label__singlevalue:hover {
    box-shadow: none;
    cursor: default;
}
.input__select--multiple {
  height: 60px;
  padding: 0;
}
.input__textarea,
.input__select--multiple--large {
  height: 120px;
  margin-top: 5px;
  border-radius: 10px;
  overflow: auto;
  text-indent: 0;
  background-image: none;
}
.tags-list {
  margin-top: 5px;
}
.tags-list--w100 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 125px;
  overflow: auto;
  max-width: 700px;
  width: 700px;
  box-sizing: border-box;
}
.tag {
  max-width: calc(100% - 40px);
  position: relative;
  padding-right: 40px;
}
.tag .btn__img {
  position: absolute;
  right: 10px;
  top: calc(50% - 12px);
  margin: 0;
  padding: 0;
}
.input__fake {
  opacity: 0;
  width: 0;
  height: 0;
}
option {
  padding: 5px 20px;
}
option:checked {
  background-color: var(--app-dark-blue) !important;
  color: var(--app-white) !important;
}
</style>
