<template>
  <main id="reporting-view">
    <div class="dashboard-header">
      <p class="dashboard-header__breadcrumb">{{ $t('templates') }}</p>
      <form class="form dashboard-header__form" @submit.prevent>
        <input type="text" class="input__search input--white-bg" :placeholder="$t('search')" v-model="searchText">
        <button v-if="searchText" id="btn-clear-text" class="btn btn--clear-text" @click.prevent="clearSearchText">
          <img src="/img/baseline_clear_white_48dp.png" :alt="$t('clear')" :title="$t('clear')" class="btn__img btn__img--small">
        </button>
      </form>
    </div>
    <div class="tabs__tab-content-actions">
      <button class="btn btn--filter" :class="{ '--filter-active': isFieldsFilterActive }" @click.prevent="fieldsFilterShowModal">
        <img src="/img/baseline_filter_list_white_48dp.png" :alt="$t('filter')" :title="$t('filter')" class="btn__img btn__img--small">{{ $t('filter') }}
      </button>
      <button class="btn btn--new" @click.prevent="createTemplate">
        <img src="/img/baseline_add_white_48dp.png" :alt="$t('create')" :title="$t('create')" class="btn__img btn__img--small">{{ $t('new-template') }}
      </button>
      <!-- FIELDS-SELECTOR-MODAL -->
      <fields-selector
        id="fields-filter-selector"
        v-if="isFieldsSelectorVisible"
        :fieldsFilter="fieldsFilter"
        :fieldsMeta="fieldsMeta"
        v-on:on-fields-filter-hide-modal="onFieldsFilterHideModal"
        v-on:on-fields-filter-change="onFieldsFilterChange"></fields-selector>
    </div>
    <div class="card">
      <div class="card__content">
        <div class="card__header">
          <div class="card__header-text">
            <h4 class="card__title">{{ $t('report-templates') }}</h4>
          </div>
          <div class="card__header-actions">
            <!-- <button class="btn" @click.prevent="deleteTemplate" :disabled="!filteredTemplates || filteredTemplates.length !== 1">
              <img src="/img/baseline_delete_white_48dp.png" :alt="$t('delete')" :title="$t('delete')" class="btn__img">{{ $t('delete') }}
            </button> -->
          </div>
        </div>
        <div class="card__header" v-if="filteredTemplates.length > 0">
          <div class="card__header-text">
            <a v-for="(data, index) in filteredTemplatesTags" :key="index" href="#" class="tag tag--with-margin tag--collapsible" :title="$t('clear')" @click.prevent="clearTemplateTag(data)">
              {{ data.label }}
              <img class="btn__img btn__img--small --clear-cursor" src="/img/baseline_cancel_white_48dp.png" :alt="$t('clear')">
            </a>
          </div>
        </div>
        <div class="scrollable-cols">
          <table class="card__table">
            <colgroup>
              <col class="--w50px">
              <col :class="[ showFullWidthName ? '--w600px' : '--w300px' ]">
              <col class="--w300px">
              <col class="--w150px">
              <col class="--w150px">
              <col class="--w100px">
            </colgroup>
            <thead>
              <tr>
                <th class="sticky --left-1">&nbsp;</th>
                <th class="sticky --left-2">
                  {{ $t('name') }}
                  <!-- <button class="btn btn--square--small" @click="changeTableOrder('name')">
                    <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': orderField === 'name' }" src="/img/baseline_unfold_more_white_48dp.png" :alt="$t('sort')">
                  </button> -->
                  <button class="btn btn--square--small btn--sticky-right" @click="showFullWidthName = !showFullWidthName">
                    <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': showFullWidthName }" src="/img/baseline_swap_horiz_white_48dp.png" :alt="$t('expand')">
                  </button>
                </th>
                <th>{{ $t('description') }}</th>
                <th>
                  {{ $t('creation-date') }}
                  <!-- <button class="btn btn--square--small" @click="changeTableOrder('created_at')">
                    <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': orderField === 'name' }" src="/img/baseline_unfold_more_white_48dp.png" :alt="$t('sort')">
                  </button> -->
                </th>
                <th>
                  {{ $t('category') }}
                  <!-- <button class="btn btn--square--small" @click="changeTableOrder('category')">
                    <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': orderField === 'name' }" src="/img/baseline_unfold_more_white_48dp.png" :alt="$t('sort')">
                  </button> -->
                </th>
                <th class="sticky --right">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr class="parent-row">
                <td colspan="6" :title="$t('default-templates')">{{ $t('default-templates') }}</td>
              </tr>
              <template v-for="(template, rowIndex) in templates.filter(item => item.public === true && item.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)">
                <tr class="child-row"
                  :key="`default-template-${rowIndex}`"
                  v-if="isRowFiltered(template)"
                  v-show="isRowVisible(rowIndex)"
                  :class="{ 'is-selected': filteredTemplates.includes(template._id), 'child-row--last-child': templates.filter(item => item.public === true).length === rowIndex + 1 }">
                  <td class="sticky --left-1">
                    <img  class="btn__img btn__img--small"
                      v-if="template.category && template.category !== ''"
                      :src="templateMetaData(template).icon"
                      :title="templateMetaData(template).label" />
                  </td>
                  <td class="sticky --left-2" :title="template.name">
                    {{ template.name }}
                  </td>
                  <td :title="template.description">{{ template.description }}</td>
                  <td :title="formatDate(template.created_at)">{{ formatDate(template.created_at) }}</td>
                  <td :title="`${$t(template.category)}`">{{ $t(template.category) }}</td>
                  <td class="--ta-r sticky --right">
                    <div class="table-cell-actions">
                      <button class="btn btn--hover" :title="$t('edit')" @click.prevent="editTemplate(template)" v-if="isAdmin">
                        <img class="btn__img" src="/img/baseline_edit_white_48dp.png">{{ $t('edit') }}
                      </button>
                    </div>
                  </td>
                </tr>
              </template>
              <tr class="parent-row">
                <td colspan="6" :title="$t('custom-templates')">{{ $t('custom-templates') }}</td>
              </tr>
              <template v-for="(template, rowIndex) in templates.filter(item => item.public !== true && item.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)">
                <tr class="child-row"
                  :key="`saved-template-${rowIndex}`"
                  v-if="isRowFiltered(template)"
                  v-show="isRowVisible(rowIndex + templates.filter(item => item.public === true).length)"
                  :class="{ 'is-selected': filteredTemplates.includes(template._id), 'child-row--last-child': templates.filter(item => item.public !== true).length === rowIndex + 1 }">
                  <td class="sticky --left-1">
                    <img  class="btn__img btn__img--small"
                      v-if="template.category && template.category !== ''"
                      :src="templateMetaData(template).icon"
                      :title="templateMetaData(template).label" />
                  </td>
                  <td class="sticky --left-2" :title="template.name">
                    {{ template.name }}
                  </td>
                  <td :title="template.description">{{ template.description }}</td>
                  <td :title="formatDate(template.created_at)">{{ formatDate(template.created_at) }}</td>
                  <td :title="`${$t(template.category)}`">{{ $t(template.category) }}</td>
                  <td class="--ta-r sticky --right">
                    <div class="table-cell-actions">
                      <button class="btn btn--hover" :title="$t('edit')" @click.prevent="editTemplate(template)">
                        <img class="btn__img" src="/img/baseline_edit_white_48dp.png">{{ $t('edit') }}
                      </button>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="pagination.total > pagination.perPage" class="card__actions">
        <div class="card__actions--left">&nbsp;</div>
        <div class="card__actions--right">
          <div class="tfoot__rows-page"
            v-for="(page, index) in new Array(pagination.totalPages)"
            :key="index"
            :class="{ 'tfoot__current-page': pagination.currentPage - 1 === index }"
            @click="setCurrentPage(index + 1)">
            {{ 1 + (index * pagination.perPage) }} - {{ Math.min((index + 1) * pagination.perPage, pagination.total) }}</div>
          <div class="tfoot__total-rows">&nbsp;{{ $t('of') }}&nbsp;{{ pagination.total }}&nbsp;</div>
          <div class="tfoot__page-actions">
            <button class="btn btn--square--small" :disabled="pagination.currentPage === 1" @click="setCurrentPage(pagination.currentPage - 1)">
              <img class="btn__img" src="/img/baseline_chevron_left_white_48dp.png" :alt="$t('prev')">
            </button>
            <button class="btn btn--square--small" :disabled="pagination.currentPage === pagination.totalPages" @click="setCurrentPage(pagination.currentPage + 1)">
              <img class="btn__img" src="/img/baseline_chevron_right_white_48dp.png" :alt="$t('next')">
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import FieldsSelector from '@/core/components/FieldsSelector.vue'
import {
  mapGetters,
  mapActions
} from 'vuex'
import {
  SHOW_MODAL,
  HIDE_MODAL,
  GET_TEMPLATES,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE
} from '@/core/action-types'
import i18n from '@/plugins/i18n'
export default {
  name: 'reporting',
  components: {
    FieldsSelector
  },
  data: function () {
    return {
      pagination: {
        currentPage: 1,
        perPage: 40,
        totalPages: 1,
        total: 0
      },
      orderField: 'none',
      orderedTableItems: [],
      searchText: '',
      fieldsFilter: [],
      isFieldsSelectorVisible: false,
      isFieldsFilterActive: false,
      fieldsMeta: [
        {
          id: 'category',
          label: this.$t('category'),
          values: [
            { id: 'category-performance', label: this.$t('performance') },
            { id: 'category-billclosure', label: this.$t('bill-closure') }
          ]
        }
      ],
      showFullWidthName: false,
      locale: i18n.locale,
      filteredTemplates: [],
      templateCategories: [
        { value: 'performance', label: this.$t('performance'), icon: '/img/baseline_speed_white_48dp.png' },
        { value: 'billclosure', label: this.$t('bill-closure'), icon: '/img/baseline_payments_white_48dp.png' }
      ],
      editTemplateForm: {
        fields: [
          {
            name: 'name',
            type: 'text',
            label: `${this.$t('name')} *`,
            title: this.$t('name'),
            value: '',
            required: true,
            maxlength: 255,
            minlength: 3,
            class: '--w75'
          },
          {
            name: 'category',
            type: 'select',
            label: `${this.$t('category')}`,
            title: this.$t('category'),
            dataList: [
              { value: 'performance', label: this.$t('performance'), icon: '/img/baseline_speed_white_48dp.png' },
              { value: 'billclosure', label: this.$t('bill-closure'), icon: '/img/baseline_payments_white_48dp.png' }
            ],
            value: '',
            required: false,
            class: '--w25'
          },
          {
            name: 'description',
            type: 'text',
            label: `${this.$t('description')}`,
            title: this.$t('description'),
            value: '',
            maxlength: 255,
            minlength: 3,
            required: false,
            class: '--w100'
          },
          {
            name: 'start_date',
            type: 'date',
            label: `${this.$t('from')}`,
            title: this.$t('from'),
            value: '',
            maxlength: 10,
            pattern: null,
            required: false,
            class: '--w25'
          },
          {
            name: 'end_date',
            type: 'date',
            label: `${this.$t('to')}`,
            title: this.$t('to'),
            value: '',
            maxlength: 10,
            pattern: null,
            required: false,
            class: '--w25'
          },
          {
            name: 'segmentation',
            type: 'select',
            label: `${this.$t('segmentation')}`,
            title: this.$t('segmentation'),
            dataList: [
              { label: this.$t('objective'), value: 'objective' },
              { label: this.$t('day'), value: 'day', disabled: true },
              { label: this.$t('gender'), value: 'gender', disabled: true },
              { label: this.$t('age'), value: 'age', disabled: true }
            ],
            value: '',
            required: false,
            class: '--w50'
          },
          {
            name: 'metrics',
            type: 'checkbox',
            label: `${this.$t('metrics')} *`,
            title: this.$t('metrics'),
            dataList: [
              { label: this.$t('budget'), value: 'budget' },
              { label: this.$t('cpr'), value: 'cpr' },
              { label: this.$t('ctr'), value: 'ctr' },
              { label: this.$t('results'), value: 'results' },
              { label: this.$t('progress'), value: 'progress' },
              { label: this.$t('spend'), value: 'spend' },
              { label: this.$t('ecpr'), value: 'ecpr' },
              { label: this.$t('impressions'), value: 'impressions' },
              { label: this.$t('reach'), value: 'reach' },
              { label: this.$t('frequency'), value: 'frequency' },
              { label: this.$t('results-rate'), value: 'results_rate' }
            ],
            value: '',
            required: true,
            class: '--w100'
          },
          {
            name: 'optionsList',
            type: 'checkbox',
            label: `${this.$t('options')}`,
            title: this.$t('options'),
            dataList: [
              { label: this.$t('summary_fields'), value: 'summary_fields' }
            ],
            value: [],
            required: false,
            class: '--w50'
          },
          {
            name: 'format',
            type: 'select',
            label: `${this.$t('format')}`,
            title: this.$t('format'),
            dataList: [
              { label: 'csv', value: 'csv' },
              { label: 'pdf', value: 'pdf' },
              { label: 'xls', value: 'xls' }
            ],
            value: '',
            required: false,
            class: '--w50'
          }
        ],
        hiddenFields: [
          {
            name: 'id',
            value: ''
          }
        ],
        originalData: {},
        hideModal: this.hideModal,
        updateTemplate: this.updateTemplate,
        showNotification: this.showNotification,
        modalClass: 'card__modal--large',
        $t: this.$t.bind(this),
        defaultSaveButtonName: this.$t('save'),
        save (query) {
          if (this.originalData.category === query.category) {
            delete query.category
          }
          if (this.originalData.description === query.description) {
            delete query.description
          }
          if (this.originalData.end_date === query.end_date) {
            delete query.end_date
          }
          if (this.originalData.metrics.sort().join(',') === query.metrics.sort().join(',')) {
            delete query.metrics
          }
          if (this.originalData.name === query.name) {
            delete query.name
          }
          // TODO: WE'LL ALWAYS SAVE THE OPTIONS (FOR THE MOMENT)
          // if (this.originalData.optionsList.includes('summary_fields') && query.optionsList.includes('summary_fields')) {
          //   delete query.options.summary_fields
          // }
          // if (this.originalData.format === query.format) {
          //   delete query.options.format
          // }
          query.options = {}
          // TODO: WE'lLL ADD MORE OPTIONS IN THE FUTURE
          if (query.optionsList.includes('summary_fields')) {
            query.options.summary_fields = 'true'
          }
          delete query.optionsList
          if (query.format !== '') {
            query.options.format = query.format
          }
          delete query.format
          if (Object.keys(query.options).length === 0) {
            delete query.options
          }
          if (this.originalData.segmentation === query.segmentation) {
            delete query.segmentation
          }
          if (this.originalData.start_date === query.start_date) {
            delete query.start_date
          }
          if (this.authData?.role === 'admin') {
            query.public = true
          }
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('template-updated'), type: 'success', icon: 'success' })
            this.fetchData()
          }
          query.onComplete = onComplete.bind(this)
          this.updateTemplate(query)
          this.hideModal()
        },
        deleteTemplate: this.deleteTemplate,
        saveDelete: function (query) {
          // TODO: PROMPT UI!
          if (window.confirm(this.$t('sure-to-delete-template'))) {
            this.deleteTemplate(query)
            this.hideModal()
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'authData',
      'templates'
    ]),
    filteredTemplatesTags () {
      return this.templates && this.templates.length > 0 && this.filteredTemplates && this.filteredTemplates.length > 0 ? this.filteredTemplates.map(templateId => {
        return {
          value: templateId,
          label: this.templates.find(template => template._id === templateId).name
        }
      }) : []
    },
    tabSavedReportsChecked () {
      return this.$route.params.tab === 'saved-reports'
    },
    tabCreateReportChecked () {
      return this.$route.params.tab === 'create-report'
    },
    isAdmin () {
      return this.authData?.role === 'admin'
    }
  },
  mounted () {
    this.fetchData()
    this.orderField = window.localStorage.getItem('orderField_templates') || 'none'
    this.fieldsFilter = window.localStorage.getItem('fieldsFilter_templates') ? window.localStorage.getItem('fieldsFilter_templates').split(',') : []
  },
  watch: {
    $route (to, from) {
      this.fetchData()
    },
    orderField (newValue, oldValue) {
      window.localStorage.setItem('orderField_templates', newValue)
    },
    fieldsFilter (newValue, oldValue) {
      window.localStorage.setItem('fieldsFilter_templates', newValue.join(','))
      this.isFieldsFilterActive = true
    },
    templates (newValue, oldValue) {
      this.pagination.total = newValue.length
      this.pagination.totalPages = Math.ceil(newValue.length / this.pagination.perPage)
      this.pagination.currentPage = 1
      this.orderedTableItems = this.orderTableByField(newValue)
    }
  },
  methods: {
    ...mapActions({
      showModal: SHOW_MODAL,
      hideModal: HIDE_MODAL,
      getTemplates: GET_TEMPLATES,
      updateTemplate: UPDATE_TEMPLATE,
      deleteTemplate: DELETE_TEMPLATE
    }),
    setCurrentPage (page) {
      this.pagination.currentPage = page
    },
    isRowVisible (index) {
      return index >= (this.pagination.currentPage - 1) * this.pagination.perPage && index < this.pagination.currentPage * this.pagination.perPage
    },
    orderTableByField (table) {
      const sortArray = function (a, b) {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        return 0
      }
      const sortTextArray = function (a, b) {
        return a.localeCompare(b)
      }
      let sortedTable = (table) ? [...table] : []
      switch (this.orderField) {
        case 'name':
          sortedTable = sortedTable.sort((a, b) => sortTextArray(a.name.toUpperCase(), b.name.toUpperCase()))
          break
        case 'category':
          sortedTable = sortedTable.sort((a, b) => sortTextArray(a.role.toUpperCase(), b.role.toUpperCase()))
          break
        case 'created_at':
          sortedTable = sortedTable.sort((a, b) => sortArray(new Date(a.start_date), new Date(b.start_date)))
          break
        case 'none':
          break
        default:
          sortedTable = sortedTable.sort((a, b) => sortArray(Number(a[this.orderField]), Number(b[this.orderField])))
      }
      return sortedTable
    },
    changeTableOrder (field) {
      this.orderField = field
      this.orderedTableItems = this.orderTableByField(this.templates)
    },
    isRowFiltered (row) {
      const filters = this.fieldsFilter.map(item => item.split('-'))
      let allFiltersPassed = {}
      filters.forEach(filter => { allFiltersPassed[filter[0]] = false })
      filters.forEach(filter => {
        if (row[filter[0]] === filter[1] || row[filter[0]] === Number(filter[1])) {
          allFiltersPassed[filter[0]] = true
        }
      })
      return Object.keys(allFiltersPassed).map(key => allFiltersPassed[key]).every(value => value === true)
    },
    fetchData () {
      this.getTemplates()
    },
    editTemplate (template) {
      this.showModal({ editItem: true, title: this.$t('edit-template'), form: this.getEditTemplateForm(template) })
    },
    getEditTemplateForm (template) {
      let modalFormData = Object.assign({}, this.editTemplateForm)
      modalFormData.hiddenFields.find(item => item.name === 'id').value = template._id
      modalFormData.originalData.name = template.name
      modalFormData.fields.find(item => item.name === 'name').value = template.name
      modalFormData.originalData.category = template.category
      modalFormData.fields.find(item => item.name === 'category').value = template.category
      modalFormData.originalData.description = template.description
      modalFormData.fields.find(item => item.name === 'description').value = template.description
      modalFormData.originalData.start_date = template.start_date ? template.start_date.split('T')[0] : ''
      modalFormData.fields.find(item => item.name === 'start_date').value = template.start_date ? template.start_date.split('T')[0] : ''
      modalFormData.originalData.end_date = template.end_date ? template.end_date.split('T')[0] : ''
      modalFormData.fields.find(item => item.name === 'end_date').value = template.end_date ? template.end_date.split('T')[0] : ''
      modalFormData.originalData.segmentation = template.segmentation
      modalFormData.fields.find(item => item.name === 'segmentation').value = template.segmentation
      modalFormData.originalData.metrics = template.metrics
      modalFormData.fields.find(item => item.name === 'metrics').value = template.metrics
      modalFormData.originalData.optionsList = template.options && template.options.summary_fields ? ['summary_fields'] : ''
      modalFormData.fields.find(item => item.name === 'optionsList').value = template.options && template.options.summary_fields ? ['summary_fields'] : ''
      modalFormData.originalData.format = template.options && template.options.format ? template.options.format : ''
      modalFormData.fields.find(item => item.name === 'format').value = template.options && template.options.format ? template.options.format : ''
      return modalFormData
    },
    addParamsToLocation () {
      const params = this.$route.query
      const queryString = Object.keys(params).map(key => {
        return (typeof params[key] === 'string') ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}` : params[key].map(keyValue => `${key}=${keyValue}`).join('&')
      }).join('&')
      window.history.pushState({}, null, `${this.$route.path}?${queryString}`)
    },
    formatDate (date) {
      return date && date !== '' ? new Date(date).toLocaleString(this.locale, { year: 'numeric', month: 'numeric', day: 'numeric' }) : ''
    },
    createTemplate () {
      this.$router.push({ name: 'templates-new', query: this.$route.query })
    },
    clearSearchText () {
      this.searchText = ''
    },
    templateMetaData (template) {
      return this.templateCategories.find(item => item.value === template.category)
    },
    // EVENTS:
    fieldsFilterShowModal () {
      this.isFieldsSelectorVisible = true
    },
    onFieldsFilterHideModal () {
      this.isFieldsSelectorVisible = false
    },
    onFieldsFilterChange ({ fieldsFilter }) {
      this.fieldsFilter = [...fieldsFilter]
    },
    onFilteredTemplatesChange (e) {
      let filteredTemplates
      if (e.target.checked) {
        filteredTemplates = [...this.filteredTemplates, e.target.value]
      } else {
        filteredTemplates = [...this.filteredTemplates.filter(item => item !== e.target.value)]
      }
      this.$emit('on-filtered-templates-change', { filteredTemplates })
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
#reporting-view {
  padding: 2rem 2rem 100px;
  display: flex;
  flex-direction: column;
}
.card__header + .card--with-header {
  margin-bottom: 20px;
}
.dashboard-header {
  display: flex;
  line-height: 36px;
  justify-content: space-between;
  padding: 10px 20px;
}
.dashboard-header__breadcrumb {
  font-size: 24px;
  flex-basis: calc(100% - 650px);
  max-width: calc(100% - 660px);
  display: flex;
}
.dashboard-header__form {
  display: flex;
  position: relative;
}
#btn-clear-text {
  position: absolute;
  left: 240px;
}
</style>
